:root {
    --sidebar-width: 320px;
}

.rbt-left-panel {
    width: var(--sidebar-width);
    background-color: var(--color-dark);
    position: fixed;
    top: 80px;
    left: 0;
    min-height: 450px;
    padding: 10px 20px 0 20px;
    padding-bottom: 0;
    border-right: 1px solid var(--color-border);
    height: calc(100% - 80px);

    @media #{$smlg-device} {
        z-index: 9;
    }

    .rbt-default-sidebar {
        position: relative;
        height: 100%;

        .rbt-default-sidebar-wrapper {
            height: calc(100vh - 360px);
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}

// Dashboard Left Navbar
.rbt-course-details-list-wrapper,
.rbt-default-sidebar-list {
    @extend %liststyle;

    li {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;

        i {
            color: var(--color-body);
            margin-right: 10px;
            font-size: 20px;
        }

        img {
            width: 35px;
            height: auto;
            margin-right: 10px;
        }

        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;

            &.rbt-feature-value {
                font-size: 12px;
            }
        }

        a {
            display: flex;
            align-items: center;
            color: var(--color-body);
            transition: 0.4s;
            padding: 10px 12px;
            width: 100%;
            border-radius: var(--radius-small);

            &:hover,
            &.active {
                color: var(--color-primary);
                background: var(--color-blackest);

                i {
                    color: var(--color-primary);
                }
            }

        }

        &.has-submenu {
            display: block;

            .collapse-btn {
                position: relative;

                &::after {
                    position: absolute;
                    content: "\e996";
                    font-family: 'feather' !important;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all 0.4s ease;
                }

                &.collapsed {
                    &::after {
                        content: "\e9b1";
                    }
                }
            }

            .submenu {
                margin-left: 30px;
            }
        }
    }
}

// Subscription Box
.subscription-box {
    position: absolute;
    bottom: 110px;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 5;
    background-color: var(--color-blackest);
    border-radius: var(--radius);

    .inner {
        display: flex;
        flex-direction: column;
        padding: 10px;

        .autor-info {
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
            border-radius: var(--radius-small);
            padding: 10px;
            transition: all 0.3s;
            position: relative;

            &:hover {
                background-color: var(--color-dark);
            }

            .author-img {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                position: relative;
                overflow: hidden;
                margin-right: 10px;

                &.active {
                    &::after {
                        content: '';
                        position: absolute;
                        right: 5px;
                        bottom: 5px;
                        height: 10px;
                        width: 10px;
                        border-radius: 50%;
                        background: var(--color-success);
                    }
                }
            }

            .author-desc {
                h6 {
                    margin-bottom: 2px;
                }

                p {
                    margin-bottom: 0;
                }
            }

            .author-badge {
                position: absolute;
                top: 10px;
                right: 10px;
                padding: 0px 5px;
                border-radius: 4px;
                background: var(--color-primary);
                color: var(--color-white);
                font-size: var(--font-size-b4);
                list-style: var(--line-height-b4);
            }
        }

        .btn-default {
            display: block;
            cursor: pointer;
        }
    }
}

// Subscription Box copyright 
.subscription-copyright {
    &.copyright-text {
        position: absolute;
        bottom: 10px;
        z-index: 3;
        margin-bottom: 0;
        width: 100%;
    }
}


.chat-content {
    .title {
        .rainbow-badge-card {
            margin-left: 5px;
        }
    }

    .download-btn {
        position: absolute;
        top: 30px;
        right: 30px;

        i {
            margin-right: 5px;
        }
    }

    .edit-actions {
        button+button {
            margin-left: 5px;
        }
    }
}



.rbt-sm-separator {
    margin: 20px 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-border);
}

.rbt-main-content {
    background: var(--color-dark);
    margin-left: var(--sidebar-width);
    min-height: 450px;
    height: auto;
    margin-bottom: 130px;
    margin-right: var(--sidebar-width);
    margin-top: 80px;

    @media #{$smlg-device} {
        margin-left: 0;
        margin-right: 0;
    }
}

.rbt-dashboard-content {
    .content-page {
        padding: 0 190px;

        @media #{$laptop-device} {
            padding: 0 50px;
        }

        @media #{$sm-layout} {
            padding: 0 20px;
        }

        @media #{$md-layout} {
            padding: 0 40px;
        }

        @media #{$lg-layout} {
            padding: 0 50px;
        }

        .chat-box-list {
            padding-left: 0;
            list-style: none;
            margin: 0;
            max-height: 90vh;
            overflow-y: scroll;
            scrollbar-width: none;
        }
    }

    .banner-area {
        padding: 30px 15px;
    }
}


.chat-box {
    margin-bottom: 30px;
    padding: 30px;
    border-radius: var(--radius);
    background-color: var(--color-blackest);
    position: relative;

    @media #{$sm-layout} {
        padding: 20px 10px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .inner {
        .chat-section {
            display: flex;
            align-items: flex-start;

            .author {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 20px;
                background-color: transparent;
                color: var(--color-primary);
                display: flex;
                align-items: center;
                justify-content: center;

                @media #{$sm-layout} {
                    height: 24px;
                    width: 24px;
                    margin-right: 5px;
                }

                svg {
                    width: 24px;
                    height: auto;
                }
            }

            .chat-content {
                max-width: calc(100% - 60px);
                width: 100%;

                @media #{$sm-layout} {
                    max-width: calc(100% - 30px);
                }

                .title {
                    margin-bottom: 10px;

                    &.color-text-off {
                        color: var(--color-text-off);
                    }
                }

                p {
                    margin-bottom: 0;
                }

                .img-box {
                    position: relative;

                    .download-btn {
                        right: 20px;
                        top: 20px;

                        @media #{$small-mobile} {
                            right: 10px;
                            top: 10px;
                            height: 30px;
                            line-height: 30px;
                            padding: 0 10px;
                            font-size: var(--font-size-b4);
                        }
                    }
                }

                .img-box-grp {
                    display: flex;

                    @media #{$sm-layout} {
                        flex-wrap: wrap;
                    }

                    .img-box+.img-box {
                        margin-left: 20px;

                        @media #{$sm-layout} {
                            margin-left: 0;
                            margin-top: 20px;
                        }
                    }
                }

                .reaction-section {
                    .btn-grp {
                        display: flex;
                        justify-content: space-between;

                        .left-side-btn {
                            @media #{$sm-layout} {
                                display: flex;
                            }
                        }

                        .react-btn {
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0 10px;
                            font-size: var(--font-size-b1);
                            line-height: var(--line-height-b1);

                            @media #{$sm-layout} {
                                display: flex;
                                margin: 0 5px;
                                font-size: var(--font-size-b2);
                                line-height: var(--line-height-b2);
                            }

                            @media #{$small-mobile} {
                                padding: 0 6px;
                                height: 30px;
                                line-height: 30px;
                                font-size: var(--font-size-b4);
                            }

                            span {
                                font-size: var(--font-size-b3);
                                line-height: var(--line-height-b3);
                                margin-left: 10px;

                                @media #{$sm-layout} {
                                    display: none;
                                }
                            }

                            &.show {
                                background-color: var(--color-primary);
                                border-color: var(--color-primary);
                            }
                        }
                    }
                }

                .video-popup {
                    &.frame-image {
                        background-color: var(--color-dark);
                    }
                }

                .typing-icon {
                    #iframe-icon {
                        background: transparent;
                        display: block;
                        shape-rendering: auto;
                        width: 50px;
                        height: 50px;
                        overflow: hidden;
                    }
                }
            }

            &+.chat-section {
                margin-top: 10px;
            }

            &.generate-section {
                display: flex;
                align-items: center;

                &:nth-child(2) {
                    .author {
                        color: var(--color-secondary);
                    }
                }
            }

            &.generate-details-section {
                .title {
                    margin-top: 10px;

                    @media #{$sm-layout} {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.image-caption {
    padding: 15px 30px;
    border-radius: var(--radius);
    background: var(--color-dark);

    .caption-title {
        margin-bottom: 0;

        @media #{$small-mobile} {
            font-size: var(--font-size-b3);
        }
    }

    @media #{$small-mobile} {
        padding: 8px 15px;
    }
}

// Dashboard Right Side panel
.rbt-right-side-panel {
    width: var(--sidebar-width);
    background: var(--color-dark);
    min-height: 450px;
    height: 100vh;
    position: fixed;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    right: 0;
    top: 80px;
    border-left: 1px solid var(--color-border);

    @media #{$smlg-device} {
        z-index: 999;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .right-side-top {
        padding: 20px;
        border-bottom: 1px solid var(--color-border);
        position: sticky;
        top: 0;
        z-index: 3;
        background: var(--color-dark);

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 10px 20px;
            position: relative;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 14px;
            border-radius: 5px;
            position: relative;
            cursor: pointer;

            span {
                font-size: var(--font-size-b3);
                line-height: var(--line-height-b3);
            }

            .icon {
                margin-right: 10px;
            }
        }
    }

    .right-side-bottom {
        padding: 20px;

        .title {
            padding: 0 12px;
            margin-bottom: 10px;
        }
    }
}

// Chat History Section 
.chat-history-section {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-border);

    .chat-history-list {
        padding-left: 0;
        list-style: none;
        margin: 0;

        .history-box {
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 5px 5px 10px;
            border-radius: 5px;
            font-size: var(--font-size-b3);
            line-height: var(--line-height-b3);
            font-weight: 400;
            letter-spacing: 0;
            position: relative;
            cursor: pointer;
            user-select: none;
            transition: all 0.3s ease;
            border: none;
            margin: 0;

            .more-info-icon {
                background: transparent;
                border: none;
                color: var(--color-heading);
                opacity: 0;
                visibility: hidden;
                font-size: var(--font-size-b1);
                line-height: var(--line-height-b1);
                display: flex;
                justify-content: center;
                align-items: center;
                height: 25px;
                width: 25px;
                border-radius: 50%;
                background-color: transparent;
                transition: all 0.3s;

                &.show {
                    background-color: var(--color-dark);
                }
            }

            &:hover {
                background: var(--color-blackest);

                .more-info-icon {
                    opacity: 0.8;
                    visibility: visible;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            &.active {
                background: var(--color-blackest);

                .more-info-icon {
                    opacity: 0.8;
                    visibility: visible;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &+.chat-history-section {
        padding-top: 20px;
    }

    &:last-child {
        padding-bottom: 0;
        border: none;
    }
}

// Small Search
.small-search {
    &.search-section {
        position: relative;

        input {
            background: transparent;
            border: 2px solid var(--color-border);
            padding: 10px 15px 10px 30px;
            border-radius: var(--radius);
            color: var(--color-body);
            font-size: var(--font-size-b3);

            &::placeholder {
                color: var(--color-body);
                font-size: var(--font-size-b3);
            }

            &:focus,
            &:focus-visible {
                border-color: var(--color-primary) !important;
                outline: none;
            }
        }

        i {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
        }
    }
}

.rbt-dashboard-page {

    .dropdown-toggle::after,
    .dropup .dropdown-toggle::after {
        display: none;
    }

    .dropdown-menu {
        border-radius: var(--radius);
        padding: 10px 10px;
        background-color: var(--color-blackest);
        box-shadow: 2px 6px 20px 10px rgb(0 0 0 / 0.5);
        z-index: 9999;

        li {
            margin: 0;

            .dropdown-item {
                font-weight: 500;
                padding: 5px 20px;
                font-size: var(--font-size-b3);
                line-height: var(--line-height-b3);
                display: flex;
                align-items: center;
                color: var(--color-body);
                border-radius: 3px;

                svg {
                    width: 15px;
                    height: auto;
                    margin-right: 10px;
                }

                i {
                    font-size: var(--line-height-b3);
                    line-height: var(--line-height-b3);
                    margin-right: 10px;
                }

                &:hover,
                &:active,
                &:focus {
                    background-color: var(--color-dark);
                    color: var(--color-primary);
                }

                &.delete-item {

                    &:hover,
                    &:active,
                    &:focus {
                        color: var(--color-danger);
                    }
                }
            }
        }
    }

}

.custom-tooltip {
    .tooltip-inner {
        background-color: var(--color-primary);
        color: var(--color-white);
        font-size: var(--font-size-b4);
    }

    .tooltip-arrow::before {
        border-top-color: var(--color-primary);
    }
}



// Header
.rbt-dashboard-header {
    position: fixed;
    text-align: center;
    width: 100%;
    margin-left: 0;
    top: 0;
    background-color: var(--color-dark);
    padding: 0 20px;

    @media #{$sm-layout} {
        padding: 0;
    }

    .mainmenu-nav {
        margin-left: 0;
    }

    .right-menu {
        ul {
            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                margin: 0;

                a {
                    color: var(--color-heading);
                    font-size: 16px;
                    font-weight: 500;
                    padding: 0 17px;
                    display: block;
                    height: 80px;
                    line-height: 80px;
                    transition: 0.3s;

                    &:hover {
                        color: #FF3BD4;
                    }
                }
            }
        }
    }

    .genarator-card {
        .title {
            font-size: var(--font-size-b3);
        }
    }

    .hamberger {
        .hamberger-button {
            @media #{$sm-layout} {
                width: 35px;
                height: 35px;
                font-size: var(--font-size-b2);
                line-height: var(--line-height-b2);
            }
        }
    }

    .mobile-menu-bar {
        @media #{$sm-layout} {
            margin-left: 0 !important;
        }
    }
}

.header-default {
    .genarator-card {
        .title {
            font-size: var(--font-size-b3);
        }

        .inner {
            padding: 30px 10px !important;
        }
    }
}



.rbt-content {
    width: 100%;
    position: relative;
    min-height: 450px;
}


// Dashboad Static Bar
.rbt-static-bar {
    position: fixed;
    width: calc(100% - (2*var(--sidebar-width)));
    margin-top: auto;
    z-index: 5;
    bottom: 0;
    padding: 15px 0;
    background-color: var(--color-dark);
    box-shadow: 0px -40px 55px -20px rgba(7, 7, 16, 1);
    transition: all 0.4s ease;
    text-align: center;

    &.area-left-expanded {
        width: calc(100% - var(--sidebar-width));

        @media #{$smlg-device} {
            width: 100%;
        }
    }

    &.area-right-expanded {
        width: calc(100% - var(--sidebar-width));

        @media #{$smlg-device} {
            width: 100%;
        }

        &.area-left-expanded {
            width: 100%;
        }
    }

    .staticbar-btn-grp {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        justify-content: center;
        display: flex;
        background: var(--color-dark);
        flex-wrap: wrap;

        li {
            margin-top: 15px;
            margin-bottom: 0;

            @media #{$sm-layout} {
                margin-top: 10px;
                padding: 0 10px;
            }

            @media #{$small-mobile} {
                margin-top: 5px;
                padding: 0 5px;
            }

            &+li {
                margin-left: 10px;

                @media #{$small-mobile} {
                    margin-left: 5px;
                }
            }

            button {
                @media #{$small-mobile} {
                    height: 30px;
                    padding: 0 5px;
                    font-size: var(--font-size-b4);
                    line-height: var(--line-height-b4) !important;
                    margin: 0;
                }
            }
        }
    }

    &.collapse-width {
        width: calc(100% - var(--sidebar-width));

        @media #{$smlg-device} {
            width: 100%;
            padding-bottom: 50px;
        }

        @media #{$sm-layout} {
            padding: 10px;
            padding-bottom: 20px;
        }

        @media #{$small-mobile} {
            padding: 10px;
        }
    }

    @media #{$smlg-device} {
        width: 100%;
    }

    @media #{$sm-layout} {
        padding: 10px;
    }

    .new-chat-form {
        margin: 0 190px;
        position: relative;
        border-radius: var(--radius);

        @media #{$sm-layout} {
            margin: 0;
        }

        @media #{$md-layout} {
            margin: 0 50px;
        }

        @media #{$lg-layout} {
            margin: 0 50px;
        }

        @media #{$laptop-device} {
            margin: 0 45px;
        }

        textarea {
            border: 2px solid var(--color-border);
            background-color: transparent;
            border-radius: var(--radius);
            outline: 0;
            display: block;
            width: 100%;
            padding: 16px 130px 16px 60px;
            font-size: 16px;
            line-height: 22px;
            max-height: 200px;

            @media #{$small-mobile} {
                padding: 12px 100px 12px 40px;
            }
        }

        .left-icons {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;

            @media #{$small-mobile} {
                left: 10px;
            }
        }

        .right-icons {
            right: 20px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;

            @media #{$small-mobile} {
                right: 10px;
            }

            .form-icon {
                &+.form-icon {
                    margin-left: 10px;
                }
            }
        }

        .form-icon {
            color: var(--color-body);
            height: 30px;
            width: 30px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            @media #{$small-mobile} {
                height: 20px;
                width: 20px;
            }

            &:hover {
                background: var(--color-dark);
            }

            i {
                font-size: var(--font-size-b1);
                line-height: var(--line-height-b1);
            }

            &.icon-plus {
                border: none;
                background: transparent;
                cursor: pointer;
                pointer-events: all;
                position: relative;

                .input-file {
                    position: absolute;
                    right: 0;
                    margin: 0;
                    padding: 0;
                    height: 100%;
                    width: 100%;
                    cursor: pointer;
                    opacity: 0;
                    filter: alpha(opacity=0);

                }
            }

            &.icon-mic {
                background: transparent;
                color: var(--color-body);
                border: none;
            }

            &.icon-send {
                background: transparent;
                color: var(--color-body);
                border: none;
            }

            &:hover {
                background: var(--color-dark);
            }
        }
    }

    .small-text {
        padding-top: 15px;
        margin-bottom: 0;
    }
}



// Rbt popup dashboard Style
.popup-dashboardleft-section {
    transition: all 0.4s ease;

    &.collapsed {
        transform: translateX(-100%);
    }
}

.popup-dashboardright-section {
    transition: all 0.4s ease;

    &.collapsed {
        transform: translateX(100%);
    }
}

.rbt-main-content {
    transition: all 0.4s ease;

    &.area-left-expanded {
        margin-left: 0;
    }

    &.area-right-expanded {
        margin-right: 0;
    }
}

.popup-dashboardleft-btn,
.popup-dashboardright-btn {
    transition: all 0.4s ease;

    &.opened {
        i {
            &.feather-menu.right {
                &::before {
                    content: "\e910";
                }
            }

            &.feather-menu.left {
                &::before {
                    content: "\e912";
                }
            }
        }
    }
}







/*---------------------------
    Rbt Show More Button  
------------------------------*/
.rbt-show-more-btn {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-primary);
    display: block;
    margin-top: 5px;
    position: relative;
    padding: 5px;
    cursor: pointer;

    &::before {
        background: linear-gradient(rgba(7, 7, 16, 0) 0%, rgba(7, 7, 16, 1) 100%);
        position: absolute;
        content: "";
        left: 0;
        bottom: 100%;
        width: 100%;
        height: 80px;
    }

    &::after {
        content: "\e92e";
        position: absolute;
        font-family: 'feather';
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &.active {
        &::before {
            background: none;
            display: none;
        }

        &::after {
            content: "\e931";
        }
    }
}


.has-show-more {
    .has-show-more-inner-content {
        max-height: 200px;
        overflow: hidden;
        position: relative;
        width: 100%;
        transition: all 0.4s;
    }

    &.active {
        .has-show-more-inner-content {
            max-height: inherit !important;
        }
    }
}


.my_switcher-3 ul li a .text {
    color: var(--color-white);
}

// Dashboard Pricing
.chatenai-tab {
    display: flex;
    justify-content: center;

    .tab-btn-grp {
        padding: 10px;
        border: 2px solid var(--color-border);
        border-radius: 100px;
        display: flex;
        justify-content: space-between !important;

        @media #{$sm-layout} {
            flex-direction: column;
        }

        button {
            padding: 10px 35px;
            font-size: var(--font-size-b1);
            line-height: var(--line-height-b1);
            font-weight: 500;
            color: var(--color-heading);
            align-items: center;
            justify-content: center;
            display: flex;
            border-radius: 100px;
            transition: all 0.3s ease;
            border: none;
            outline: none;

            @media #{$small-mobile} {
                padding: 10px 15px;
            }

            &.active {
                background: var(--color-primary);
                color: var(--color-white);
            }

            &:focus-visible,
            &:focus-within,
            &:focus {
                color: var(--color-white);
            }

            .rainbow-badge-card {
                margin-left: 10px;
                font-size: var(--font-size-b4);
                line-height: var(--line-height-b4);
                background: none;
            }
        }
    }
}

.expand-btn-grp {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
        background: var(--color-blackest);
        width: 40px;
        height: 40px;
        border-radius: 100%;
        color: var(--color-primary);
        border: none;
        font-size: var(--font-size-b1);
        border: 2px solid var(--color-border);

        @media #{$sm-layout} {
            width: 35px;
            height: 35px;
            font-size: var(--font-size-b2);
            line-height: var(--line-height-b2);
        }

        &.popup-dashboardright-btn {
            @media #{$small-mobile} {
                margin-right: 0;
                margin-left: 3px;
            }
        }

        &.popup-dashboardleft-btn {
            margin-right: 20px;

            @media #{$sm-layout} {
                margin-right: 10px;
            }
        }

        &:hover,
        &.collapsed {
            background: var(--color-primary);
            color: var(--color-white);
            border-color: var(--color-primary);
        }
    }
}

.mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu.with-mega-item-2 {
    width: 600px;
    overflow: hidden;
    box-shadow: 0 0 20px 5px rgba(7, 7, 16, 0.1);
    padding: 0;
    border-top: none;
    border: none;

    .genarator-card {
        &:hover {
            transform: translateY(-5px);
        }
    }
}

.mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu.small {
    width: 300px;
}



// Small Carosel Bannner
.chatenai-small-slider {
    .inner {
        background: var(--color-secondary);
        display: flex;
        border-radius: var(--radius);
        justify-content: space-between;
        overflow: hidden;
        align-items: flex-end;

        @media #{$sm-layout} {
            flex-direction: column;
            align-items: flex-start;
        }

        &.bg-one {
            background: var(--color-secondary);
        }

        &.bg-two {
            background: var(--color-primary);
        }

        &.bg-three {
            background: var(--color-primary-gradient-start);
        }

        &.bg-four {
            background: var(--color-primary-alt);
        }

        .content {
            padding: 50px 30px;
            padding-right: 0;

            .title {
                margin-bottom: 10px;
                color: var(--color-white);
            }

            p {
                color: var(--color-white);
            }
        }
    }

    .img-section {
        display: flex;
        max-width: 50%;
        height: 100%;

        img {
            height: 100%;
            max-height: 165px;
        }

        @media #{$sm-layout} {
            max-width: 100%;
        }
    }
}

.rbt-dashboard-content {
    .slick-dots {
        display: flex;
        position: absolute;
        bottom: 30px;
        left: 40px;
    }

    .rainbow-slick-dot .slick-dots li button::after {
        background: var(--color-white);
        box-shadow: inset 0 0 0 5px var(--color-white);
        opacity: 0.6;
    }

    .rainbow-slick-dot .slick-dots li.slick-active button::after {
        box-shadow: inset 0 0 0 1px var(--color-white);
        opacity: 0.8;
    }
}

.welcome-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .content-section {
        display: flex;
        align-items: center;

        .icon {
            font-size: var(--h3);
            margin-right: 10px;
        }

        .title {
            margin-bottom: 0;
        }
    }

    .btn-default {
        cursor: pointer;

        @media #{$small-mobile} {
            margin-top: 20px;
        }
    }
}



/*-------------------------
    Admin User Settings
--------------------------*/


.rbt-admin-profile {
    display: flex;

    .admin-thumbnail {
        margin-right: 12px;

        img {
            width: 40px;
            min-width: 40px;
            height: 40px;
            border-radius: 100%;
            border: 2px solid var(--color-border);
            padding: 3px;

            @media #{$small-mobile} {
                width: 30px;
                min-width: 30px;
                height: 30px;
                padding: 0;
            }
        }
    }

    .admin-info {
        display: flex;
        flex-wrap: wrap;

        .name {
            color: var(--color-heading);
            font-weight: 500;
            display: block;
            line-height: 1;
            margin-bottom: 2px;
            font-size: var(--font-size-b1);

            @media #{$large-mobile} {
                flex-basis: 100%;
                text-align: left;
            }
        }

        .rbt-btn-link {
            font-size: var(--font-size-b3);
            color: var(--color-primary);
            position: relative;

            &::after {
                position: absolute;
                content: "";
                left: 0;
                bottom: 0;
                background: var(--color-primary);
                width: 0;
                height: 2px;
                transition: 0.3s;
            }

            &:hover {
                &::after {
                    width: 100%;
                }
            }
        }
    }
}

.rbt-user-menu-list-wrapper {
    .user-list-wrapper {
        margin: 0 -12px;
        list-style: none;
        padding: 0;

        li {
            margin: 0;

            a {
                display: flex;
                padding: 5px 12px;
                align-items: center;
                border-radius: 3px;
                color: var(--color-body);

                &.active {
                    color: var(--color-primary);
                    background: var(--color-dark);
                }

                img,
                i {
                    max-height: 17px;
                    margin-right: 7px;
                }

                i {
                    font-size: 17px;
                }

                span {
                    font-size: 14px;
                }
            }

            &:hover {
                a {
                    color: var(--color-primary);
                    background: var(--color-dark);
                }
            }
        }
    }
}

.rbt-user {
    height: 80px;
    line-height: 80px;
    margin: 0 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;

    @media #{$small-mobile} {
        padding: 0;
        margin: 0;
        margin-left: 0;
        margin-left: 3px;
    }
}

.rbt-user-wrapper {
    position: relative;
    z-index: 2;

    .admin-img {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        border-radius: 100%;
        color: var(--color-primary);
        border: none;
        font-size: var(--font-size-b1);
        overflow: hidden;

        img {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            border: 2px solid var(--color-border);
            padding: 3px;
            min-width: 40px;

            @media #{$small-mobile} {
                width: 35px;
                min-width: 35px;
                height: 35px;
                padding: 0;
            }
        }
    }

    @media #{$large-mobile} {
        position: static;
    }

    .rbt-user-menu-list-wrapper {
        @extend %rbt-dropdown-type-1;
        background: var(--color-blackest);
        min-width: 250px;
        border-radius: 0 0 10px 10px;
        border: 1px solid var(--color-border);
        border-top: none;

        @media #{$lg-layout} {
            left: auto;
            right: 0;
        }

        @media #{$md-layout} {
            left: auto;
            right: 0;
        }

        @media #{$sm-layout} {
            left: auto;
            right: 0;
        }

        @media #{$large-mobile} {
            left: 20px;
            right: 20px;
            width: auto;
        }

        .inner {
            padding: 25px 30px;

            hr {
                margin-left: -30px;
                margin-right: -30px;
            }

            .rbt-admin-profile {
                padding-bottom: 20px;
            }
        }
    }

    &:hover {
        .rbt-user-menu-list-wrapper {
            @extend %rbt-hover-dropdown-type-1;

        }
    }

    &.right-align-dropdown {
        .rbt-user-menu-list-wrapper {
            right: 0;
            left: auto;

            @media #{$large-mobile} {
                right: 20px;
                left: 20px;
                width: auto;
            }
        }
    }
}