/*======================== 
    Common Style Css
==========================*/

/* Theme Gradient */
.theme-gradient {
    background: linear-gradient(90deg, #12B5DE -30%, #7130C3 30%, #FF3BD4 90%);
    text-transform: capitalize;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.theme-gradient-secondary {
    background: linear-gradient(to right, var(--color-secondary-gradient-start), var(--color-secondary-gradient-end));
    text-transform: capitalize;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.theme-gradient-tertiary {
    background: linear-gradient(to right, var(--color-tertiary-gradient-start), var(--color-tertiary-gradient-end));
    text-transform: capitalize;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}




.bg-lessdark-gradient {
    background: linear-gradient(180deg, var(--color-lessdark) 20%, rgba(19, 19, 19, 0.4));
}


.bg-theme-gradient {
    background-color: transparent;
    background-image: linear-gradient(90deg, var(--color-primary) 10%, var(--color-primary) 50%, var(--color-primary) 90%);
}

.bg-primary-gradient {
    background-color: var(--color-primary);
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-primary) 75%, var(--color-secondary) 100%) 95%/200% 100%;
}




@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .theme-gradient {
        color: var(--color-secondary);
        background: none !important;
    }
}

/* Heading Font  */
.h1 {
    font-size: var(--h1) !important
}

.h2 {
    font-size: var(--h2) !important
}

.h3 {
    font-size: var(--h3) !important
}

.h4 {
    font-size: var(--h4) !important
}

.h5 {
    font-size: var(--h5) !important
}

.h6 {
    font-size: var(--h6) !important
}



/* Text Color  */

.color-primary {
    @extend %color-primary;
}

.color-primary {
    @extend %color-primary;
}

.color-primary {
    @extend %color-primary;
}

.color-primary {
    @extend %color-primary;
}

.color-secondary {
    @extend %color-secondary;
}

.color-tertiary {
    @extend %color-tertiary;
}

.color-primary {
    @extend %color-primary;
}

.color-primary {
    @extend %color-primary;
}

.color-primary {
    @extend %color-primary;
}

.color-primary-darker {
    @extend %color-primary-darker;
}

.color-secondary-darker {
    @extend %color-secondary-darker;
}

.color-primary-darker {
    @extend %color-primary-darker;
}

.color-primary-darker {
    @extend %color-primary-darker;
}

.color-heading {
    @extend %color-heading;
}

.color-body {
    @extend %color-body;
}

.color-dark {
    @extend %color-dark;
}

.color-darker {
    @extend %color-darker;
}

.color-darkest {
    @extend %color-darkest;
}

.color-black {
    @extend %color-black;
}

.color-blacker {
    @extend %color-blacker;
}

.color-blackest {
    @extend %color-blackest;
}

.color-border {
    @extend %color-border;
}

.color-gray {
    @extend %color-gray;
}

.color-midgray {
    @extend %color-midgray;
}

.color-light {
    @extend %color-light;
}

.color-lighter {
    @extend %color-lighter;
}

.color-lightest {
    @extend %color-lightest;
}

.color-white {
    @extend %color-white;
}




//===== BG Ncc Colors =====//

.bg-primary-color {
    background: var(--color-primary);
}

.bg-color-primary-alt {
    background-color: var(--color-primary-alt) !important;
}

.bg-secondary-color {
    background: var(--color-secondary)
}

.bg-tertiary-color {
    background-color: var(--color-tertiary)
}

.bg-color-primary {
    background-color: var(--color-primary) !important;
}

.bg-color-primary {
    background-color: var(--color-primary) !important;
}

.bg-color-primary {
    background-color: var(--color-primary) !important;
}

.bg-color-secondary {
    background-color: var(--color-secondary) !important;
}

.bg-color-tertiary {
    background-color: var(--color-tertiary) !important;
}

.bg-color-primary {
    background-color: var(--color-primary) !important;
}

.bg-color-primary {
    background-color: var(--color-primary) !important;
}

.bg-color-primary {
    background-color: var(--color-primary) !important;
}

.bg-color-primary-darker {
    background-color: var(--color-primary-darker) !important;
}

.bg-color-secondary-darker {
    background-color: var(--color-secondary-darker) !important;
}

.bg-color-primary-darker {
    background-color: var(--color-primary-darker) !important;
}

.bg-color-primary-darker {
    background-color: var(--color-primary-darker) !important;
}

.bg-color-heading {
    background-color: var(--color-heading) !important;
}

.bg-color-body {
    background-color: var(--color-body) !important;
}

.bg-color-dark {
    background-color: var(--color-dark) !important;
}

.bg-color-darker {
    background-color: var(--color-darker) !important;
}

.bg-color-darkest {
    background-color: var(--color-darkest) !important;
}

.bg-color-black {
    background-color: var(--color-black) !important;
}

.bg-color-blacker {
    background-color: var(--color-blacker) !important;
}

.bg-color-blackest {
    background-color: var(--color-blackest) !important;
}

.bg-color-border {
    background-color: var(--color-border) !important;
}

.bg-color-gray {
    background-color: var(--color-gray) !important;
}

.bg-color-midgray {
    background-color: var(--color-midgray) !important;
}

.bg-color-light {
    background-color: var(--color-light) !important;
}

.bg-color-lighter {
    background-color: var(--color-lighter) !important;
}

.bg-color-lightest {
    background-color: var(--color-lightest) !important;
}

.bg-color-white {
    background-color: var(--color-white) !important;
}

.bg-color-white-off {
    background-color: var(--color-text-off) !important;
}

.bg-color-bg-one {
    background-color: var(--color-bg-1) !important;
}

.bg-color-success {
    background-color: var(--color-success) !important;
}

.bg-color-danger {
    background-color: var(--color-danger) !important;
}

.bg-color-warning {
    background-color: var(--color-warning) !important;
}

.bg-color-info {
    background-color: var(--color-info) !important;
}

.bg-gradient-1 {
    background: linear-gradient(94deg, #dd00ac 10.66%, #7130c3 53.03%, #410093 96.34%, rgba(255, 0, 238, 0.26) 191.41%, rgba(255, 59, 212, 0) 191.43%)
}


/* Radius */
.radius-small {
    @extend %radius-small;
}

.radius {
    @extend %radius;
}

.radius-big {
    @extend %radius-big;
}


/* Font Weight */

.w-300 {
    @extend %w-300;
}

.w-400 {
    @extend %w-400;
}

.w-500 {
    @extend %w-500;
}

.w-600 {
    @extend %w-600;
}

.w-700 {
    @extend %w-700;
}

.w-800 {
    @extend %w-800;
}

.w-900 {
    @extend %w-900;
}



/* Shadows */

.shadow-primary {
    @extend %shadow-primary;
}

.shadow-light {
    @extend %shadow-light;
}

.shadow-lighter {
    @extend %shadow-lighter;
}



/* Others  */
.liststyle {
    padding: 0;
    margin: 0;
    list-style: none;
}

.radius {
    @extend %radius;
}

%transition-transform {
    transition: var(--transition-transform);
}

.transition-transform {
    @extend %transition-transform;
}


.list-icon {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        display: flex;
        align-items: center;
        margin: 15px 0;

        @media #{$sm-layout} {
            font-size: 16px;
        }

        .icon {
            width: 30px;
            background-color: var(--color-blackest);
            height: 30px;
            border-radius: 100%;
            display: inline-block;
            position: relative;
            margin-right: 9px;
            min-width: 30px;
            min-height: 30px;

            &.background-transparent {
                background-color: transparent;
            }

            i {
                width: 20px;
                height: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.rainbow-sub-badge {
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 18px;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 12px;
    display: inline-block;
    padding: 7px 14px;
    background-color: var(--color-blackest);
    box-shadow: var(--shadow-lighter);
}

.max-width-800 {
    max-width: 800px;
}



.bg_dot-mask {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;
    // background-image: url(../../images/bg/dot-bg.png);
    background-position: center;
}

.bg-blend-top {
    -webkit-mask-image: linear-gradient(180deg, transparent 0, #000 40%);
    mask-image: linear-gradient(180deg, transparent 0, #000 40%);
}



.feature-list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        display: flex;
        align-items: center;

        .icon {
            background: var(--color-secondary);
            width: 20px;
            height: 20px;
            border-radius: 100%;
            color: var(--color-white);
            text-align: center;
            min-width: 20px;
            font-size: 12px;

            i {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                font-size: 14px;
            }
        }

        .title-wrapper {
            margin-left: 13px;

            .title {
                font-size: 16px;
                margin: 0;
            }

            .text {
                margin-bottom: 0;
            }
        }
    }
}

.rainbow-callto-action {
    .feature-list {
        display: flex;
        margin-top: 10px;

        li+li {
            margin-left: 30px;
        }
    }
}

.chatenai-separator {
    &.has-position-bottom {
        position: absolute;
        width: 100%;
        height: auto;
        bottom: -2px;
        left: 0;
        right: 0;

        @media #{$sm-layout} {
            bottom: -5px;
        }
    }

    &.has-position-top {
        position: absolute;
        width: 100%;
        height: auto;
        left: 0;
        right: 0;
        bottom: 100%;
        top: auto;
    }
}

.separator-animated {
    position: relative;
    overflow: hidden;
    height: 2px;
    width: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0) 0%, rgba(111, 100, 233, 0.60) 50%, rgba(0, 0, 0, 0) 100%);

    &.animated-true {
        &::before {
            content: '';
            position: absolute;
            width: 3%;
            height: 100%;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%,
                    rgba(255, 255, 255, 0) 0%,
                    var(--color-primary) 50%,
                    rgba(0, 0, 0, 0) 100%);
            backdrop-filter: blur(20px);
            animation: separateline 5s ease-in-out infinite;
        }
    }
}

@keyframes separateline {
    0% {
        left: 0;
    }

    100% {
        left: 110%;
    }
}

.bg-blur-filter {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -1;
    width: 100%;
    height: 500px;
    transform: translate(-50%, -50%);
    background-image: linear-gradient(45deg, var(--color-primary), var(--color-primary));
    opacity: 0.25;
    -webkit-filter: blur(100px);
    filter: blur(100px);
}

.theme-shape {
    position: relative;
    z-index: 2;
    overflow: hidden;

    &::before {
        position: absolute;
        left: -250px;
        top: 250px;
        right: auto;
        bottom: auto;
        z-index: -1;
        width: 500px;
        height: 500px;
        border-radius: 1000px;
        background-image: linear-gradient(45deg, var(--color-primary), var(--color-primary));
        opacity: 0.2;
        filter: blur(100px);
        content: "";
    }

    &::after {
        position: absolute;
        z-index: -1;
        width: 500px;
        height: 500px;
        border-radius: 1000px;
        opacity: 0.2;
        filter: blur(100px);
        content: "";
        left: auto;
        top: -250px;
        right: -250px;
        bottom: auto;
        background-image: linear-gradient(45deg, var(--color-secondary), var(--color-tertiary));

    }
}



.gradient-btn-shadow {
    box-shadow: inset 0 0 60px whitesmoke,
        inset 20px 0 80px #f0f,
        inset -20px 0 80px #0ff,
        inset 20px 0 300px #f0f,
        inset -20px 0 300px #0ff,
        0 0 50px #fff,
        -10px 0 80px #f0f,
        10px 0 80px #0ff;
}


.chatai-sec {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    background-color: var(--color-blackest);
    gap: 0 5px;

    .box {
        position: relative;
        font-weight: var(--p-bold);
        text-transform: uppercase;
        font-size: var(--font-size-b3);
        color: var(--color-dark);

        &:nth-child(odd) {
            &:before {
                content: ' chaten AI ';
            }
        }

        &:nth-child(even) {
            &:before {
                content: 'Text transform';
                filter: hue-rotate(180deg);
            }
        }

        &.coloranimate {
            color: rgba(172, 172, 172, 0.2);
            text-shadow: 0 0 5px rgba(172, 172, 172, 0.2),
                0 0 20px rgba(172, 172, 172, 0.2),
                0 0 50px rgba(172, 172, 172, 0.2), ;
        }
    }

    .frame-image {
        position: absolute;
        z-index: 10;
        width: 50%;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
    }
}

.color-off {
    opacity: 0.5;
}

.top-flashlight {
    position: relative;

    &::before {
        content: "";
        width: 150px;
        height: 150px;
        background: var(--color-primary);
        position: absolute;
        left: 50%;
        top: -60px;
        transform: translateY(-50%) translateX(-50%);
        border-radius: 100%;
        filter: blur(70px);
    }

    &.leftside {
        &::before {
            left: 0;
            top: 0;
        }
    }

    &.light-xl {
        &::before {
            width: 300px;
            filter: blur(140px);
        }
    }
}

.bottom-flashlight {
    position: relative;

    &::before {
        content: "";
        width: 50px;
        height: 50px;
        background: var(--color-primary);
        position: absolute;
        left: 50%;
        bottom: -50%;
        transform: translateY(-50%) translateX(-50%);
        filter: blur(20px);
    }
}

// Border flashlight
.border-gradient {
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(94deg, #dd00ac 10.66%, #7130c3 53.03%, #410093 96.34%, rgba(255, 0, 238, 0.26) 191.41%, rgba(255, 59, 212, 0) 191.43%);
        width: 200%;
        height: 200%;
        transition: 0.5s, top 0s, left 0s;
        border-radius: var(--radius);
    }

    &::after {
        content: '';
        position: absolute;
        inset: 2px;
        background: var(--color-blackest);
        border-radius: var(--radius);
    }

    &>* {
        z-index: 2;
        position: relative;
    }

    &.bg-secondary {
        &::before {
            background: radial-gradient(var(--color-secondary), transparent,
                    transparent);
        }
    }

    &.bg-tertiary {
        &::before {
            background: radial-gradient(var(--color-tertiary), transparent,
                    transparent);
        }
    }
}


.rating-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rating {
    display: flex;
    gap: 5px;

    a {
        i {
            font-size: var(--font-size-b1);
            line-height: var(--line-height-b1);
            color: #FFB800;

            @media #{$sm-layout} {
                font-size: 10px;
            }
        }
    }
}



// Pre Loader CSS
.preloader {
    background: var(--color-dark);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
}

@keyframes bgg {
    0% {
        background: var(--color-primary-light);
    }

    50% {
        background: var(--color-primary);
    }

    100% {
        background: var(--color-primary);
    }
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 70px;
    height: 70px;
    margin: auto;
}

.loader .circle {
    position: absolute;
    width: 50px;
    height: 50px;
    opacity: 0;
    transform: rotate(225deg);
    animation-iteration-count: infinite;
    animation-name: orbit;
    animation-duration: 5.5s;
    left: 3px;
    top: -5px;
}

.loader .circle:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    animation: bgg 1500ms ease-in alternate infinite;
}

.loader .circle:nth-child(2) {
    animation-delay: 240ms;
}

.loader .circle:nth-child(3) {
    animation-delay: 480ms;
}

.loader .circle:nth-child(4) {
    animation-delay: 720ms;
}

.loader .circle:nth-child(5) {
    animation-delay: 960ms;
}

@keyframes orbit {
    0% {
        transform: rotate(225deg);
        opacity: 1;
        animation-timing-function: ease-out;
    }

    7% {
        transform: rotate(345deg);
        animation-timing-function: linear;
    }

    30% {
        transform: rotate(455deg);
        animation-timing-function: ease-in-out;
    }

    39% {
        transform: rotate(690deg);
        animation-timing-function: linear;
    }

    70% {
        transform: rotate(815deg);
        opacity: 1;
        animation-timing-function: ease-out;
    }

    75% {
        transform: rotate(945deg);
        animation-timing-function: ease-out;
    }

    76% {
        transform: rotate(945deg);
        opacity: 0;
    }

    100% {
        transform: rotate(945deg);
        opacity: 0;
    }
}

.rbt-pagination {
    margin: -8px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$sm-layout} {
        margin: -4px;
    }

    li {
        margin: 8px;

        @media #{$sm-layout} {
            margin: 4px;
        }

        a {
            width: 45px;
            height: 45px;
            background: var(--color-blackest);
            border-radius: 6px;
            text-align: center;
            color: var(--color-body);
            transition: 0.4s;
            font-weight: 500;
            box-shadow: var(--shadow-1);
            display: flex;
            align-items: center;
            justify-content: center;

            @media #{$sm-layout} {
                width: 45px;
                height: 45px;
            }

            i {
                font-size: 22px;
                font-weight: 500;
            }
        }

        &.active,
        &:hover {
            a {
                background: var(--color-primary);
                color: var(--color-white);
            }
        }
    }
}

.chatenai-bg-gradient {
    background: linear-gradient(107deg, #171A21 0.52%, rgba(23, 26, 33, 0.00) 99.49%);
}

.rbt-avatars {
    min-width: 70px;
    max-width: 70px;

    &.size-lg {
        min-width: 120px;
        max-width: 120px;
        width: 120px;
        height: 120px;
    }

    &.size-sm {
        min-width: 52px;
        max-width: 52px;
    }

    img {
        border-radius: 100%;
        width: 100%;
        background: var(--color-bg-1);
        padding: 4px;
        border: 2px solid var(--color-dark);
        object-fit: cover;
    }
}